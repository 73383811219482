<template>
  <div class="myPopupContent">
    <editor-topbar :title="headline" @closeClicked="closeEditor"/>
    <div class="whitePopupContent">
      <div class="err_msg commonError" v-show="missingFieldErrorMessage.length > 0">
        <icon name="fa-exclamation-circle"/> <span>{{ missingFieldErrorMessage }}</span>
      </div>
      <div class="commonFormHorScroll" :style="maxHeightStyle">
        <div class="commonColListWrap">
          <ul class="commonColList twoColList recipientsList">
            <li class="column column1">
              <div class="innerDv">
                <div class="commonColListWrap">
                  <ul class="commonColList twoColList">
                    <li class="padTN column">
                      <div class="innerDv">
                        <h2 class="headline">{{ x('recipients') }}</h2>
                        <dl class="input_list">
                          <p>
                            {{ x('showOnlyExternalUsers') }}
                            <input type="checkbox" v-model="showOnlyExternalUsers">
                          </p>

                          <visit-plan-editor-list-box :is-disabled="isReadonly"
                                                 v-model="selectedUserIds"
                                                 :items="filteredUsers"
                                                 title="users"
                                                 key-prop="id"
                                                 name-prop="firstName,lastName"/>

                        </dl>
                      </div>
                    </li>
                    <li class="padTN column">
                      <div class="innerDv">
                        <div class="fltRht greenTxt">{{ recipients.length }}</div>
                        <h2 class="headline">&nbsp;</h2>
                        <ul class="recipientsSearchList" v-if="recipients.length">
                          <li v-for="recipient in recipients" :key="recipient.id">
                            <a title="Remove" @click="removeRecipient(recipient)"
                               v-if="!isReadonly">
                              <icon name="fa-close"/>
                            </a>
                            <span>{{ fullName(recipient) }}</span>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="commonColListWrap">
                  <ul class="commonColList twoColList">
                    <li class="padTN column">
                      <div class="innerDv">
                        <h2 class="headline">{{ x('customers') }}:</h2>
                        <dl class="input_list">

                          <visit-plan-editor-list-box :is-disabled="isReadonly"
                                                 v-model="selectedChainIds"
                                                 :items="allChains"
                                                 title="chains"
                                                 key-prop="id"
                                                 name-prop="name"/>

                          <visit-plan-editor-list-box :is-disabled="isReadonly"
                                                 v-model="selectedCustomerIds"
                                                 :items="allCustomers"
                                                 title="customers"
                                                 key-prop="id"
                                                 name-prop="name"/>

                        </dl>
                      </div>
                    </li>
                    <li class="padTN column">
                      <div class="innerDv">
                        <div class="fltRht greenTxt">{{ customersInVisitPlan.length }}</div>
                        <h2 class="headline">&nbsp;</h2>
                        <template v-if="isLoadingCustomers">
                          <p class="customersLoading">
                            <icon name="fa-cog fa-spin"/>
                          </p>
                        </template>
                        <template v-else>
                          <ul class="recipientsSearchList" v-if="customersInVisitPlan.length">
                            <li v-for="customer in customersInVisitPlan"
                                :key="customer.id" :class="{ isVisited: isVisited(customer) }">
                              <a title="Remove" @click="removeCustomer(customer)"
                                 v-if="!isReadonly">
                                <icon name="fa-close"/>
                              </a>
                              <span>{{ customer.name }}</span>
                            </li>
                          </ul>
                        </template>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="column column2">
              <div class="innerDv">
                <h2 class="headline">{{ x('content') }}</h2>
                <div class="commonColListWrap">
                  <ul class="commonColList twoColList">
                    <li class="column">
                      <div class="innerDv">
                        <dl class="input_list" v-if="schemaItemByName('periodFrom')">
                          <data-editor-input-field
                            :schema="schemaItemByName('periodFrom')"
                            v-model="editedData.periodFrom"
                            :should-validate="shouldValidate"
                            @validate="inputFieldDidValidate"
                            :isDisabled="isReadonly"
                            :is-add-mode="isAddMode"
                          />
                        </dl>
                      </div>
                    </li>
                    <li class="column">
                      <div class="innerDv">
                        <dl class="input_list" v-if="schemaItemByName('periodTo')">
                          <data-editor-input-field
                            :schema="schemaItemByName('periodTo')"
                            v-model="editedData.periodTo"
                            :should-validate="shouldValidate"
                            @validate="inputFieldDidValidate"
                            :isDisabled="isReadonly"
                            :is-add-mode="isAddMode"
                          />
                        </dl>
                      </div>
                    </li>
                  </ul>
                </div>
                <dl class="input_list">
                  <data-editor-input-field
                    v-for="item in normalVisibleSchemaItems"
                    :schema="schemaItemByName(item.fieldName)"
                    :key="item.fieldName"
                    v-model="editedData[item.fieldName]"
                    :isDisabled="isReadonly"
                    :should-validate="shouldValidate"
                    @validate="inputFieldDidValidate"
                    :is-add-mode="isAddMode"
                  />
                </dl>
              </div>
            </li>
            <li class="column column3">
              <div class="innerDv">
                <h2 class="headline">KORT</h2>
                <map-component class="map" :points="customerLocations" />
              </div>
            </li>
          </ul>
        </div>
      </div>

      <br>
      <div class="button-row">
        <button class="lsc-button lsc-rounded-10" type="submit"
                v-if="!isAddMode"
                @click="orderVisitPlanReport">
          <template v-if="didSendReport">
            <icon name="fa-send"/> {{ x('reportOrdered') }}
          </template>
          <template v-else-if="isSendingReport">
            <icon name="fa-cog fa-spin"/> {{ x('orderingReportDots' )}}
          </template>
          <template v-else>
            <icon name="fa-send"/> {{ x('orderReport')}}
          </template>
        </button>
        <button class="lsc-button lsc-rounded-10 red marginLeft" type="submit"
                @click="closeEditor">
          <icon name="fa-close"/>{{ x('cancel') }}
        </button>
        <button v-if="missingFieldErrorMessage.length === 0 && isReadonly === false"
                class="lsc-button lsc-rounded-10 green marginLeft" @click="submitForm" type="submit">
          <template v-if="isSaving">
            <icon name="fa-cog fa-spin" />
          </template>
          <template v-else>
            <icon name="fa-cloud-upload"/>{{ x('save') }}
          </template>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { sortedUniqBy, uniq, difference } from 'lodash';
  import { httpGet, httpPost, httpPut } from '@/classes/httpHelper';
  import VisitPlanEditorListBox from '@/components/VisitPlans/VisitPlanEditorListBox';
  import MapComponent from '@/components/MapComponent';
  import composeSendReport from '@/components/VisitPlans/VisitPlanEditor-SendReport';
  import DataEditorInputField from '../Announcements/Editor/DataEditorInputField';
  import EditorTopbar from '../Announcements/Editor/EditorTopBar';
  import translate from '../Mixins/Translate';

  export default {
    name: 'visit-plan-editor',
    components: {
      MapComponent,
      EditorTopbar,
      DataEditorInputField,
      VisitPlanEditorListBox,
    },
    mixins: [translate],
    emits: ['editorIsReady', 'columnClassComputed'],
    setup() {
      return {
        ...composeSendReport(),
      };
    },
    data() {
      return {
        allUsers: [],
        selectedUserIds: [],
        selectedChainIds: [],
        selectedCustomerIds: [],

        /** The combined selected list of recipients. */
        recipients: [],

        /** The combined selected list of customers to visit in the visit plan */
        customersInVisitPlan: [],

        /** The contents of the editable fields */
        editedData: {},

        missingFieldErrorMessage: '',

        finalUserList: [],

        maxHeightStyle: {
          maxHeight: '400px',
        },

        // This property is a trigger that causes datepicker fields to retrieve data
        // (it uses jquery and is not reactive),
        // and all input fields to validate and show any error messages.
        // Remember to await this.$nextTick() to let its effects take place.
        shouldValidate: false,

        validatesOk: true,

        // Indicates whether only external users should be shown in the list.
        showOnlyExternalUsers: true,

        isLoadingCustomers: true,

        isSaving: false,
      };
    },
    computed: {
      isAddMode() {
        return !this.editorData.id;
      },

      isReadonly() {
        return !this.isAddMode
          && this.editorData.item.status !== 'active';
      },

      headline() {
        let headline = '';
        if (this.isAddMode) {
          headline = this.x('createNewVisitPlan');
        } else {
          headline = this.x('editVisitPlan');
        }
        if (this.editorData.item.name) {
          headline += ` - "${this.editorData.item.name}"`;
        }
        // if (!this.isAddMode && this.completedCustomers !== '') {
        //   headline += ` (${this.completionPercentage}%)`;
        // }
        return headline;
      },

      /** Returns a list of the field items that should have common UI treatment. */
      normalVisibleSchemaItems() {
        if (!this.editorData) return null;
        return this.editorData.dataSchema.filter((s) => s.fieldName !== 'periodFrom'
          && s.fieldName !== 'periodTo'
          && s.userVisible);
      },
      filteredUsers() {
        if (this.showOnlyExternalUsers) {
          return this.allUsers.filter((user) => user.userRole === 'external' || user.userRole === 'both');
        }
        return this.allUsers;
      },
      completedCustomers() {
        if (!this.editedData || !this.editedData.customers) return [];
        return this.editedData.customers.filter((customer) => customer.visits != null);
      },
      completionPercentage() {
        if (!this.editedData || !this.editedData.customers) return '';
        const customerCount = this.editedData.customers.length;
        return Math.round((this.completedCustomers.length * 100) / customerCount);
      },
      customerLocations() {
        return this.customersInVisitPlan.map((c) => ({
          latitude: c.latitude,
          longitude: c.longitude,
          style: this.isVisited(c) ? 'normal' : 'alert',
        }));
      },
      ...mapState(['config', 'user']),
      ...mapState('DataEditorStore', ['editorData', 'isLoading']),
      ...mapState('CachingStore', {
        allCustomers: 'customers',
        allChains: 'chains',
      }),
    },
    watch: {
      selectedUserIds() {
        this.refreshRecipientList();
      },
      selectedCustomerIds() {
        console.log('SelectedCustomerIds changed.');
        this.refreshCustomerList();
      },
      selectedChainIds(newValue, oldValue) {
        console.log(newValue.length, oldValue.length);
        if (oldValue.length > newValue.length) {
          const removedValues = difference(oldValue, newValue);
          // This will be called for every click, so there is only one value.
          this.removeChain(removedValues[0]);
        }
        this.refreshSelectedCustomerIds();
      },
      allCustomers() {
        this.refreshCustomerList();
        this.refreshRecipientList();
      },
    },
    async mounted() {
      await this.loadAllUsers();
      await this.initialize();
    },
    methods: {
      isVisited(customer) {
        const c = this.editedData.customers.find((i) => i.id === customer.id);
        return c && c.visits && c.visits.length > 0;
      },
      schemaItemByName(fieldName) {
        if (!this.editorData) return null;
        const items = this.editorData.dataSchema.filter((item) => item.fieldName === fieldName);
        if (items.length > 0) return items[0];
        return null;
      },

      async loadAllUsers() {
        this.allUsers = await httpGet('users');
      },

      fullName(user) {
        return `${user.firstName} ${user.lastName}`;
      },

      /** Consolidates all the selected checkboxes and generates a list of recipients */
      refreshRecipientList() {
        const unsortedRecipients = this.allUsers.filter((user) => this.selectedUserIds.indexOf(user.id) >= 0);
        this.recipients = sortedUniqBy(unsortedRecipients, (x) => `${x.firstName} ${x.lastName}`.toLowerCase());
      },

      /** Consolidates all the selected checkboxes and generates a list of recipients */
      refreshCustomerList() {
        if (this.allCustomers == null) return;
        console.log('RefreshCustomerList');
        const unsortedCustomers = this.allCustomers.filter((customer) => {
          if (this.selectedCustomerIds.indexOf(customer.id) >= 0) return true;
          if (this.selectedChainIds.indexOf(customer.chainId) >= 0) return true;
          return false;
        });

        this.customersInVisitPlan = sortedUniqBy(unsortedCustomers, 'name');
      },

      /** Removes the recipient and also checks if any
       * checkmarks should be removed from the listboxes. */
      removeRecipient(user) {
        this.recipients = this.recipients.filter((recipient) => recipient.id !== user.id);
        this.selectedUserIds = this.selectedUserIds.filter((id) => id !== user.id);
      },

      refreshSelectedCustomerIds() {
        const that = this;
        this.selectedChainIds.forEach((chainId) => {
          that.allCustomers.forEach((customer) => {
            if (customer.chainId === chainId) that.selectedCustomerIds.push(customer.id);
          });
        });

        this.selectedCustomerIds = uniq(this.selectedCustomerIds);
      },

      removeChain(chain) {
        console.log('Removing chain:', chain);
        const idsToRemove = this.customersInVisitPlan.filter((cust) => cust.chainId === chain.id);
        this.selectedCustomerIds = this.selectedCustomerIds.filter((id) => idsToRemove.indexOf(id) > -1);
      },

      removeCustomer(customer) {
        console.log('Remove customer', customer);
        // this.customersInVisitPlan = this.customersInVisitPlan.filter((c) => c.id !== customer.id);
        this.selectedCustomerIds = this.selectedCustomerIds.filter((id) => id !== customer.id);

        if (!this.isEmpty(customer.chainId)) {
          this.selectedChainIds = this.selectedChainIds.filter((id) => id !== customer.chainId);
        }
      },

      /** Checks that there is data for all fields, returns an array of missing fields. */
      validateItemAgainstSchema(item, schema) {
        const missingFields = [];
        for (const schemaItem of schema) {
          if (!(schemaItem.fieldName in item)) {
            missingFields.push(schemaItem.translationsName !== null
              ? this.x(schemaItem.translationsName) : schemaItem.fieldName);
          }
        }
        return missingFields;
      },

      async initialize() {
        const data = this.editorData;
        const missingFields = this.validateItemAgainstSchema(data.item, data.dataSchema);

        if (missingFields.length > 0) {
          this.missingFieldErrorMessage = missingFields.join(', ');
          this.missingFieldErrorMessage = `${this.x('editViewMissingField')} (${missingFields.join(', ')})`;
        }
        const visibleControlCount = data.dataSchema.filter((f) => f.userVisible).length;

        data.dataSchema.sort((a, b) => a.fieldSort - b.fieldSort);

        this.editedData = { ...this.editorData.item };
        if (this.editedData.customers == null) this.editedData.customers = [];
        if (this.editedData.userIds == null) this.editedData.userIds = [];
        this.selectedUserIds = (this.editedData.userIds || []).map((obj) => obj.userId);
        this.selectedCustomerIds = (this.editedData.customers || []).map((obj) => obj.id);

        await this.$store.dispatch('closeMenu');
        this.$emit('columnClassComputed', 'max-Width1350');
        await this.$nextTick();

        const popupSetting = this.calculateAvailableHeightAndClassForDynPopup2(visibleControlCount);
        this.maxHeightStyle = {
          maxHeight: `${popupSetting.availableHeight}px`,
        };
        this.$store.commit('DataEditorStore/setIsLoading', false);
        this.$emit('editorIsReady');

        // Ensure that customers are loaded into cache
        await this.$store.dispatch('CachingStore/requireCustomers');
        await this.$store.dispatch('CachingStore/requireDataType', { dataType: 'chains' });

        // TODO: Handle resize event
        this.isLoadingCustomers = false;
      },

      closeEditor() {
        this.$store.commit('DataEditorStore/closeEditor');
      },

      calculateAvailableHeightAndClassForDynPopup2(visibleControlCount) {
        const whitePopupHeight = 30; // marTop=15 & marBot=15
        const subGridHeight = 50;
        const whitePopupContentHeight = 50; // padTop=20 & padBot=20 & borTop=5 & borBot=5
        const buttonDivHeight = 37;
        const eachElementHeightRequirement = 100;
        let availableHeight = window.innerHeight
          - whitePopupHeight
          - subGridHeight
          - whitePopupContentHeight
          - buttonDivHeight
          - 20; // buffer height 20 to tackle common error

        if (availableHeight < 250) {
          availableHeight = 250;
        }

        let noOfFieldsInOneColumn = Math.round(availableHeight / eachElementHeightRequirement);
        if (noOfFieldsInOneColumn < 3) {
          noOfFieldsInOneColumn = 3;
        }

        let columnListCountClass = 'threeColList';
        let maxWidthClass = 'max-Width950';

        if (noOfFieldsInOneColumn >= visibleControlCount) {
          columnListCountClass = 'oneColList';
          maxWidthClass = 'max-Width350';
        } else if (noOfFieldsInOneColumn * 2 >= visibleControlCount) {
          columnListCountClass = 'twoColList';
          maxWidthClass = 'max-Width480';
        }

        return {
          availableHeight,
          maxWidthClass,
          columnListCountClass,
        };
      },

      inputFieldDidValidate(message) {
        console.log('Input field validation: ', message);
        if (message !== '') {
          if (this.validatesOk) this.validatesOk = false;
        }
      },

      async orderVisitPlanReport() {
        const visitPlanId = this.editedData.id;
        await this.orderReport(visitPlanId);
      },

      async submitForm() {
        if (this.isSaving) return;
        console.log('SubmitForm');
        // Triggers validation and datepicker value retrieval
        this.shouldValidate = true;
        await this.$nextTick();

        if (!this.validatesOk) return;

        this.isSaving = true;

        this.editedData.userIds = this.recipients.map((recipient) => recipient.id);
        this.editedData.customerIds = this.selectedCustomerIds;

        console.log('About to save');
        const result = await this.dataEditorSave();
        console.log('Saved');

        if (this.isAddMode) {
          // TODO: Consider if this could go through vuex instead
          // Should reference DataTable component
          this.editorData.rowComponent.updateAfterCreate(result.data);
        } else {
          // Should reference DataRow component
          this.editorData.rowComponent.updateAfterEdit(result.data);
        }
        this.closeEditor();
      },

      async dataEditorSave() {
        this.editedData.origin = 'admin';
        try {
          const postData = JSON.stringify(this.editedData);
          console.log('Data to save: ', postData);
          let result;
          if (this.isAddMode) {
            result = await httpPost('visit-plans', postData);
          } else {
            result = await httpPut(`visit-plans/${this.editedData.id}`, postData);
          }

          console.log('Received result of post: ', result);
          if (result.error) {
            return {
              errorMessage: result.error.message,
              data: null,
            };
          }
          return {
            errorMessage: '',
            data: result,
          };
        } catch (e) {
          console.error(e);
          return {
            errorMessage: 'An error occurred during save.',
            data: null,
          };
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .commonColList.twoColList {
    .column1 {
      width: 40% !important;

      .customersLoading {
        text-align: center;
        color: #bfbfbf;
        font-size: 30px;
      }
    }

    .column2 {
      width: 29% !important;
    }

    .column3 {
      width: 29% !important;
    }
  }

  .button-row {
    text-align: right;

    .marginLeft {
      margin-left: 15px;
    }
  }

  .isVisited {
    background-color: #9dd79d !important;
  }

  .map {
    display: block;
    height: 450px;
    margin-bottom: 10px;
  }

</style>
