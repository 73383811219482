<template>
  <div class="announcement-list-box">
    <dt>
      <a v-if="!isDisabled" class="fltRht greenTxt" @click="selectAll()">
        {{ areAllSelected ? x('deselectAll') : x('selectAll') }}
      </a>
      <a class="greenTxt" @click="showItemList = !showItemList">
        <i class="fa" :class="[showItemList ? 'fa-minus' : 'fa-plus']"/>
      </a> {{config.translation[title]}}
    </dt>
    <dd v-show="showItemList">
      <input type="text" placeholder="Search" v-model="searchText" maxlength="50">
    </dd>
    <dd class="overFlowYScroll items" v-show="showItemList">
      <div class="padTB5" v-for="item in visibleItems" :key="itemId(item)">
        <input type="checkbox" class="fltLft marT2" :value="itemId(item)" v-model="selectedItems"
               :disabled="isDisabled">
        <span class="padL15 displayBlock">{{ itemName(item) }}</span>
      </div>
    </dd>
  </div>
</template>

<script>
  /**
   * This component works almost exactly like the list-filter-item.
   * It ought to be replaced by that one, once the refactoring of the announcement box is complete.
   * The css and actual html classes are probably different, though.
   */
  import { mapState } from 'vuex';
  import ComponentEventNames from '@/enums/component-event-names';
  import translate from '../Mixins/Translate';

  export default {
    name: 'visit-plan-editor-list-box',
    props: {
      // The selected values of the items.
      modelValue: {
        type: Array,
        required: true,
      },
      // If true, the component is not editable.
      isDisabled: {
        type: Boolean,
        default: false,
      },
      // A list of all the items to show in the component. The selected items should be a subset of these.
      items: {
        type: Array,
      },
      // The title of the box
      title: {
        type: String,
        required: true,
      },
      // The property of the items to use as key and for the selected items array.
      keyProp: {
        type: String,
        required: true,
      },
      // The property of the item objects to use as display value in the list.
      nameProp: {
        type: String,
        required: true,
      },
    },
    emits: [ComponentEventNames.updateModelValue],
    mixins: [translate],
    data() {
      return {
        showItemList: false,
        searchText: '',
        selectedItems: this.modelValue,
      };
    },
    computed: {
      // Indicates that the name prop is not compound. This flag is used to speed up rendering.
      hasSimpleNameProp() {
        return this.nameProp.indexOf(',') === -1;
      },
      /** All items filtered by any search text  */
      visibleItems() {
        if (!this.items) return [];
        if (this.searchText === '') {
          return [...this.items]; // allItems cloned by spread operator
        }
        return this.items.filter((item) => item[this.nameProp]
          .toLowerCase()
          .indexOf(this.searchText.toLowerCase()) > -1);
      },
      areAllSelected() {
        return this.selectedItems.length >= this.visibleItems.length;
      },
      ...mapState(['config']),
    },
    watch: {
      modelValue(newValue) {
        this.selectedItems = newValue;
      },
      selectedItems() {
        this.$emit(ComponentEventNames.updateModelValue, this.selectedItems);
      },
    },
    methods: {
      /** Function providing item display names. */
      itemName(item) {
        return this.hasSimpleNameProp
          ? item[this.nameProp]
          : this.nameProp
            .split(',')
            .reduce((prev, curr) => `${prev} ${item[curr] || ''}`, '');
      },
      /** Function providing item identifiers. */
      itemId(item) {
        return item[this.keyProp];
      },
      /** Performs select all or deselect all depending on
       * whether or not all are currently selected. */
      selectAll() {
        if (this.areAllSelected) {
          this.selectedItems = [];
        } else {
          // Union between all previously selected items
          // (including ones that might not be visible right now)
          // with all currently visible.
          const visibleIds = this.visibleItems.map((i) => i[this.keyProp]);
          this.selectedItems = [
            ...this.selectedItems,
            ...visibleIds,
          ];
        }
      },
    },
  };
</script>

<style scoped>
  .announcement-list-box {
    margin-bottom: 12px;
  }

  a {
    cursor: pointer;
  }

  .items {
    box-shadow: inset 0 1px 1px 0 rgba(0 0 0 0.1);
    background-color: rgba(1, 1, 1, 0.05);
    max-height: 350px;
  }
</style>
