import { ref, nextTick } from 'vue';
import { useStore } from 'vuex';
import { httpPost } from '@/classes/httpHelper';

export default function composeSendReport() {
  const store = useStore();
  const isSendingReport = ref(false);
  const didSendReport = ref(false);

  const methods = {
    async orderReport(visitPlanId) {
      if (isSendingReport.value) return; // prevent double-click
      isSendingReport.value = true;
      await nextTick();

      const data = {
        visitIds: [visitPlanId],
        reportType: 'visitPlan',
        emailRecipients: [store.state.user.email],
      };
      try {
        await httpPost('orderreport', data);
        didSendReport.value = true;
      } catch (e) {
        console.error('An error happened while ordering report: ', e);
      } finally {
        isSendingReport.value = false;
      }
    },
  };

  return {
    isSendingReport,
    didSendReport,
    ...methods,
  };
}
